import { useMemo, useState } from 'react';

import { ReactComponent as GrowthCatalystIcon } from '@/assets/icons/sidebar/growthCatalyst.svg';
import { ReactComponent as ChatIcon } from '@/assets/icons/sidebar/growthMentor.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/sidebar/home.svg';
import { ReactComponent as UserGroupIcon } from '@/assets/icons/sidebar/teamManagement.svg';
// import { ReactComponent as CirclePlayIcon } from '@/assets/icons/sidebar/ventureVerse.svg';
import { useAuthorization, ROLES } from '@/lib/authorization';

import { SideMenuCollapsible, SideMenuCollapsibleProps } from './SideMenuCollapsible';

export const SideNavigation = () => {
  const { checkAccess } = useAuthorization();
  const [active, setActive] = useState<string>('');
  const navigation = useMemo<SideMenuCollapsibleProps[]>(
    () => [
      {
        label: 'NAVIGATION',
        sectionTitle: true,
      },
      {
        label: 'Home',
        to: 'dashboard',
        allowed: true,
        Icon: HomeIcon,
      },
      {
        label: 'Team Management',
        allowed: true,
        Icon: UserGroupIcon,
        to: 'teams',
        // children: [
        //   {
        //     label: 'Teams',
        //     to: 'teams',
        //     allowed: true,
        //     isDefault: true,
        //   },
        //   {
        //     label: 'Create',
        //     to: 'teams/create-team',
        //     allowed: checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }),
        //   },
        // ],
      },
      {
        label: 'GrowthCatalyst',
        allowed: true,
        Icon: GrowthCatalystIcon,
        children: [
          {
            label: 'Build Reflections',
            to: 'assessment',
            allowed: checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }),
            isDefault: true,
          },
          {
            label: 'Take Reflections',
            to: 'assessment/take',
            allowed: true,
          },
          {
            label: 'See Results',
            to: 'assessment/results',
            allowed: true,
          },
        ],
      },
      // {
      //   label: 'VentureVerse',
      //   allowed: true,
      //   Icon: CirclePlayIcon,
      //   to: 'ventureverse',
      // },
      {
        label: 'RESOURCES',
        sectionTitle: true,
        addPreSeparator: true,
      },
      {
        label: 'GrowthMentor',
        allowed: true,
        Icon: ChatIcon,
        to: 'growth-mentor',
      },
    ],
    [checkAccess]
  );

  return (
    <>
      {navigation.map((item, index) => (
        <SideMenuCollapsible
          key={item.label}
          {...item}
          index={index}
          active={active}
          setActive={setActive}
        />
      ))}
    </>
  );
};
