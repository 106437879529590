import { useMemo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { ROLES } from '@/lib/authorization';

import { AssessmentPublished } from '../components/builder/AssessmentPublished';
import { Builder } from '../components/builder/Builder';
import { DraftsList } from '../components/draftList';
import { ASSESSMENT_TEMPLATES, ASSESSMENT_TYPES } from '../types';

export const Build = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();

  const [published, publishedSet] = useState(false); // TODO: add published api logic
  const [isAssessmentLoading, isAssessmentLoadingSet] = useState(false);
  const [displayDrafts, setDisplayDrafts] = useState(true);
  const [appSettings, setAppSettings] = useState({}); // TODO: Maybe this can be moved to a higher level

  const backPath = useMemo(() => {
    if (searchParams.get('template')) return '..';
    if (pathname.indexOf('assessment') > -1) return '../../';
    return '../../../assessment';
  }, [pathname, searchParams]);

  useEffect(() => {
    const settings = localStorage.getItem('appSettings') || '{}';
    const settingsJSON = JSON.parse(settings);
    setAppSettings(settingsJSON);
    setDisplayDrafts(settingsJSON?.displayDraftSidebar);
  }, []);

  useEffect(() => {
    localStorage.setItem('appSettings', JSON.stringify(appSettings));
  }, [appSettings]);

  const createNewTemplate = useCallback(() => {
    navigate(
      `../build?template=${ASSESSMENT_TEMPLATES.BLANK}&type=${ASSESSMENT_TYPES.TEAM}&role=${ROLES.COFOUNDER}&template_name=${ASSESSMENT_TEMPLATES.BLANK}`,
      { replace: true }
    );
  }, [navigate]);

  const handleDisplayDrafts = (storeUpdate = true) => {
    setDisplayDrafts(!displayDrafts);
    if (storeUpdate) {
      setAppSettings({ displayDraftSidebar: !displayDrafts });
    }
  };

  return (
    <ContentLayout
      title={published ? 'GrowthCatalyst' : 'Form Builder'}
      addNavigate={createNewTemplate}
      addNavigateLabel="Build custom form"
      back={backPath}
    >
      {published ? (
        <AssessmentPublished setPublish={publishedSet} />
      ) : (
        <div className="builder__container">
          {displayDrafts && (
            <DraftsList
              isAssessmentLoading={isAssessmentLoading}
              handleDisplayDrafts={handleDisplayDrafts}
            />
          )}
          <Builder
            published={published}
            publishedSet={publishedSet}
            isAssesmentLoadingSet={isAssessmentLoadingSet}
            displayDrafts={displayDrafts}
            handleDisplayDrafts={handleDisplayDrafts}
          />
        </div>
      )}
    </ContentLayout>
  );
};
