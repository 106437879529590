import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { AssessmentsRoutes } from '@/features/assessments';
import { RequireAuth } from '@/features/auth';
import { ChatRoutes } from '@/features/chat';
import { ClientsRoutes } from '@/features/clients';
import { Dashboard } from '@/features/dashboard';
import { UpdatedTerms } from '@/features/misc';
import { MyProfileRoutes } from '@/features/my_profile';
import { TeamsRoutes } from '@/features/teams';
import { UserRoutes } from '@/features/users';
// import { VentureverseRoutes } from '@/features/ventureverse';
import { ROLES } from '@/lib/authorization';

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const protectedRoutes = (
  checkAccess: ({ allowedRoles }: { allowedRoles: number[] }) => boolean
) => {
  return [
    {
      path: '/app',
      element: (
        <RequireAuth>
          <App />
        </RequireAuth>
      ),
      children: [
        {
          path: '/users/*',
          element: checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <UserRoutes />
          ) : (
            <Navigate to="/app/dashboard" />
          ),
        },
        { path: '/assessment/*', element: <AssessmentsRoutes /> },
        { path: '/create-client/*', element: <ClientsRoutes /> },
        { path: '/profile/*', element: <MyProfileRoutes /> },
        { path: '/teams/*', element: <TeamsRoutes /> },
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/growth-mentor/*', element: <ChatRoutes /> },
        // { path: '/ventureverse/*', element: <VentureverseRoutes /> },
        { path: '/', element: <Navigate to="dashboard" /> },
      ],
    },
    {
      path: '/terms',
      element: (
        <RequireAuth>
          <UpdatedTerms />
        </RequireAuth>
      ),
    },
  ];
};
