import { useAuthenticator } from '@aws-amplify/ui-react';

import { ReactComponent as Arrow } from '@/assets/icons/arrow.svg';
import { ContentLayout } from '@/components/Layout';
import {
  GrowthCatalystWidget,
  GrowthMentorWidget,
  // TodoList,
  VentureverseWidget,
} from '@/features/dashboard';

export const Dashboard = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user?.attributes) return null;

  return (
    <ContentLayout
      title={`Hi ${user.attributes?.name}!`}
      rightWrapper={
        <div className="report-bug-wrapper">
          <a
            className="report-bug"
            href="https://docs.google.com/forms/d/e/1FAIpQLSd4PAbqNMNvmR-HQzSy8-jbNEhiZvv_g_hUXF00rV4nmIwiTQ/viewform"
            target="blank"
          >
            Report a bug
          </a>
          <Arrow />
        </div>
      }
      customClassName="dashboard-container"
    >
      {/* <TodoList /> */}
      {/* <div className="mb-3" /> */}
      <div className="flex flex-row mt-6 mb-6">
        <VentureverseWidget className="flex-1 mr-6" />
        <GrowthCatalystWidget className="flex-1 ml-2" />
      </div>
      <div className="mb-3" />
      <GrowthMentorWidget />
    </ContentLayout>
  );
};
